<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.operation") }}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="11">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.transport") }}
                </span>
                <el-form-item prop="transport_id" class="label_mini">
                  <select-transport
                    :id="form.transport_id"
                    :size="'medium'"
                    v-model="form.transport_id"
                    :placeholder="$t('message.transport')"
                    @staffId="setStaffId"
                  ></select-transport>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <div class="pulus" style="margin-top:25px" @click="dialogCreate = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                  <i class="el-icon-plus"></i>
                </div>
              </el-col>
              <!-- <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.car_driver") }}
                </span>
                <el-form-item prop="staff_id" class="label_mini">
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                    :placeholder="$t('message.car_driver')"
                  ></select-staff>
                </el-form-item>
              </el-col> -->
               <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.car_driver") }}
                </span>
                <el-form-item  class="label_mini">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.driver"
                      v-model="form.driver"
                      :size="'medium'"
                      :placeholder="$t('message.car_driver')"
                    ></crm-input>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">  {{$t("message.employees")}} </span>
                <el-form-item  class="label_mini">
                  <select-staff
                    :id="form.assistant_staff_ids"
                    v-model="form.assistant_staff_ids"
                    :multiple="true"
                    :size="'medium'"
                    :placeholder="$t('message.staff')"
                  ></select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_date") }}
                </span>
                <el-form-item prop="left_date" class="label_mini">
                  <crm-date-picker
                    :date="form.left_date"
                    :size="'medium'"
                    :placeholder="$t('message.left_date')"
                    v-model="form.left_date"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                  </crm-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_time") }}
                </span>
                <el-form-item prop="left_time" class="label_mini">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                   :size="'medium'"
                    :placeholder="$t('message.left_time')"
                    v-model="form.left_time"
                    style="width: 100%"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.exit_target") }}
                </span>
                <el-form-item prop="reason_id" class="label_mini">
                   <div class="seloction mb20">
                      <el-select
                        v-model="form.reason_id"
                        :placeholder="$t('message.reason')"
                        class="w-100"
                        :size="'medium'"
                        popper-class="my-data-khan"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                        <el-option
                          v-for="(reason, index) in reasons"
                          :key="'guestreason-' + index"
                          :label="reason.name"
                          :value="reason.id"
                        ></el-option>
                      </el-select>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.comment") }}
                </span>
                <el-form-item prop="comment" class="label_mini">
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    :type="'textarea'"
                    :inputValue="form.comment"
                    v-model="form.comment"
                    :placeholder="$t('message.exit_target')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                  <select-company
                  :size="'medium'"
                  :style="'font-weight:normal'"
                    :id="form.company_id"
                    v-model="form.company_id"
                  ></select-company>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
    <el-dialog
      :visible.sync="dialogCreate"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreate"
      :title="$t('message.add_car')"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-car-create
        ref="dialogCreateChild"
        dialog="dialogCreate"
      ></crm-car-create>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import selectTransport from "@/components/filters/inventory/select-transport";
import selectStaff from "@/components/filters/inventory/select-staff";
import CrmCarCreate from "./car/crm-car-create";
import show from "@/utils/mixins/show";
export default {
  mixins: [form, drawer, show],
  name: "OperationController",
  components: { selectTransport, selectStaff, selectCompany, CrmCarCreate },
  data() {
    return {
      dialogCreate:false
    };
  },
  mounted() {
    this.reasonsAction();
  },
  computed: {
    ...mapGetters({
      rules: "operation/rules",
      model: "operation/model",
      columns: "operation/columns",
      reasons: "operation/reasons",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "operation/update",
      show: "operation/show",
      empty: "operation/empty",
      reasonsAction: "operation/reasons",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              if (res.status == 201) {
                this.parent().listChanged();
                this.close();
                this.empty();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    setStaffId(staff_id) {
      this.form.staff_id = staff_id;
    },
  },
};
</script>

